.Service-Text {
    font-size: 18px;
    display: flex;

    li {
        margin-right: 7px;
        text-decoration: none;
    }
}

.Service-Link {
    text-decoration-color: $text;
    color: $primary;
}

#linkIcon {
    margin-left: 7px;
}