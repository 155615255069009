.large-tag {
    margin-right: 15px;
    margin-bottom: 0px;
    border-radius: 10px;
    font-weight: bold;
    padding: 5px 15px;
}

.small-tag {
    padding: 4px 8px;
    margin-right: 5px;
}

.type-tag {
    text-transform: uppercase;
    background-color: $primary;
    border: solid 3px $primary;
    color: $secondary;
    border-radius: 20px;
}

.affiliation-tag {
    text-transform: capitalize;
    border: solid 3px;
    border-radius: 20px;
}

.services-tag {
    box-shadow: 0px -4px 4px rgba(0, 0, 0, 10%);
    color: $text;
    border-top-right-radius: 20px;
    border-top-left-radius: 20px;

    width: 100%;
    margin: 10px 0px;
    padding: 9px 8px;

    font-size: 17px;

    font-weight: bolder;

    text-align: center;
}

.website-tag {
    border: solid 3px $primary;
    border-radius: 20px;
    color: $primary;

    img {
        margin: 0px;
    }
}

@media (max-width: 800px) {
    .small-tag {
        font-size: 12px;
    }

    .large-tag {
        font-size: 17px;
        margin-right: 10px;
        margin-bottom: 10px;
        padding: 5px;
    }

    .services-tag {
        font-size: 20px !important;
    }
}