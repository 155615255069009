.NavBar {
    display: flex;
    align-items: center;
    justify-content: space-between;

    font-weight: 200;
    padding: 35px 20px;
    width: 100%;
    height: 100px;
}

.NavBar-Links {
    display: flex;
    justify-self: center;
}

.DesktopNavbar {
    align-items: center;
}

#NavBar-TryCompassBtn {
    margin-left: 20px;
    font-weight: bolder;
    padding: 15px 20px;
}

.navLink {
    margin: 0px 15px;
    text-decoration: none;
    font-weight: bolder;
    font-size: 20px;
}

.cl-userButtonAvatarBox {
    margin: 0px 15px;
}

.cl-userButtonAvatarBox:active {
    outline: none;
}

.navLink:hover {
    color: $secondary;
    cursor: pointer;
}

.navLink:focus {
    color: $secondary;
}

.CompassLogo {
    width: 200px;
    height: 40px;
}

@media (max-width: 700px) {
    .NavBar {
        padding: 25px 10px;
    }
}