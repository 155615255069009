.MobileRefinement {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    background-color: $primary;
    /* Black*/
    overflow-x: hidden;
    overflow: hidden;
    /* Disable horizontal scroll */
    transition: 0.3s;
    padding-top: 60px;
    /* Place content 60px from the top */
    /* 0.5 second transition effect to slide in the sidenav */

    h3, button {
        display: block;
    }

    h3 {
        width: 300px;
        padding: 8px 8px 8px 32px;
        color: $secondary;
    }
}

.MobileRefinement-Root {
    background-color: $primary;
    color: $secondary;
    width: 250px;
    border-radius: 15px;
}

.MobileRefinement-ShowMore {
    color: #7E41FF;
    background-color: $secondary;
    border: none;
    border-radius: 5px;
    margin: 8px 8px 8px 32px;
}

#MobileRefinement-CloseBtn, #MobileRefinement-CloseBtn:active, #MobileRefinement-CloseBtn:focus, #MobileRefinement-CloseBtn:hover {
    width: 20px;
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;

    outline: none;
    box-shadow: none;
    border: none;
}