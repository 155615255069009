.About {
    justify-content: center;
    align-items: center;
    color: $primary;
    width: 100%;
    max-width: 1800px;
    margin: 0px;
}

.About-HeroText {
    font-weight: 300;
    text-align: center;
}

.About-Subcontainer {
    justify-content: center;
    align-items: center;

    h1 {
        font-family: $hero;
    }

    h3 {
        -webkit-box-orient: none;
        -webkit-line-clamp: 10;
        overflow: visible;
    }
}

.About-ProblemStatement {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background: $primary-gradient;
    padding: 50px 0px;
    width: 100%;
    div {
        width: 50%;
    }

    h3 {
        margin-right: 20px;
        color: $secondary;
        font-weight: 300;
    }
}

.About-Students {
    padding: 50px 0px;
}

.About-StudentList {
    justify-content: space-between;
    list-style: none;
    padding: 0px;
    margin-left: 20px;
    width: 100%;

    li {
        font-size: 20px;
        text-indent: -25px;
        margin-left: 10px;
        margin-bottom: 25px;
    }
}

.About-StudentListText {
    align-items: flex-start;
    padding-right: 70px;
    margin-left: 40px;
}

.About-StudentListImg {
    justify-content: flex-end;
    width: 50%;
}

.About-Transition {
    background: $secondary-gradient;
    text-align: center;
    width: 100%;
    height: 400px;
}

.About-Product {
    margin: 0px 35px;
    justify-content: center;
    align-items: center;
    h1 {
        margin-bottom: 40px;
        text-align: center;
    }

    h4 {
        margin: 15px 45px;
        text-align: center;
    }
}

.About-ProductText {
    margin: 20px 0px;
    width: 80%;
}

.About-ProductTiles {
    display: grid;
    grid-template-columns: repeat(5, 1fr);
    grid-template-rows: repeat(5, 1fr);
    grid-column-gap: 0px;
    grid-row-gap: 0px;
    width: 100%;
    height: 875px;

    div {
        display: grid;
        text-align: center;
        background-color: $primary;
        border-radius: 28px;
        margin: 5px;
        padding: 20px;

        img {
            align-self: center;
            justify-self: center;
        }

        h3 {
            margin-top: 20px;
            align-self: flex-end;
        }
    }
}

.About-ProductTile-Place, .About-ProductTile-Search, .About-ProductTile-Curated {
    justify-content: flex-start;
}

.About-ProductTile-Place {
    grid-area: 1 / 1 / 4 / 4;
}

.About-ProductTile-Search {
    grid-area: 4 / 1 / 6 / 4;
}

.About-ProductTile-Curated {
    grid-area: 1 / 4 / 6 / 6;
}

.About-Action {
    justify-content: center;
    align-items: center;
    text-align: center;
    width: 100%;
    height: 500px;
    background-image: url('contourbackground.png');

    button, button:active, button:hover, button:focus {
        font-size: 30px;
        padding: 10px 20px;
        border-radius: 30px;
        margin-bottom: 30px;
        a {
            text-decoration: none;
            color: $primary;
        }
    }

    h1 {
        margin-bottom: 60px;
    }
    
    h3 {
        color: $secondary;
        font-style: italic;
    }

    margin-bottom: 0px;
}

.About-bold {
    font-weight: bold;
}

/// IMAGES
#About-DesktopPhone {
    width: 650px;
}

#About-Maze {
    width: 45%;
}

#About-StressedStudent {
    width: 40%;
    margin-right: 40px;
}

#About-Tablet {
    width: 650px;
    margin-bottom: 50px;
}

#About-StackedCards {
    width: 80%;
}

#About-StackedSearches {
    width: 70%;
}

#About-PhoneView {
    width: 75%;
}

@media (max-width: 1200px) {
    #About-DesktopPhone {
        width: 550px;
    }

    #About-Tablet {
        width: 550px;
    }

    .About-ProductTiles {
        height: 750px;
    }
}

@media (max-width: 750px) {
    #About-DesktopPhone, #About-Tablet {
        width: 80%;
    }

    .About-ProblemStatement {
        display: grid;
        flex-direction: column;

        h3 {
            margin: 0px;
        }

        div {
            justify-self: center;
            text-align: center;
            width: 80%;
            margin-top: 40px;
        }
    }

    .About-Students {
        display: grid;
        flex-direction: column;
    }

    .About-StudentList {
        li {
            font-size: 15px;
        }
    }

    .About-Product {
        margin: 0px 15px;
    }

    .About-ProductText {
        width: 95%;

        h4 {
            font-size: 20px;
            margin: 5px;
        }
    }

    .About-ProductTiles {
        display: flex;
        flex-direction: column;
        height: auto;
    }

    #About-PhoneView {
        width: auto;
        height: 400px;
    }

    .About-ProductTile-Place, .About-ProductTile-Search, .About-ProductTile-Curated {
        h3 {
            font-size: 20px;
        }
    }

    #About-StressedStudent {
        justify-self: center;
        width: 70%;
    }

    #About-Maze {
        justify-self: flex-start;
        width: 70%;
    }

    .About-Action {
        button {
            font-size: 20px;
        }

        h3 {
            font-size: 18px;
        }
    }
}