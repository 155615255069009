#edit-text {
    align-self: center;
    color: $edit;
    font-weight: bold;
    margin-bottom: 0;
    margin-right: 5px;
}

#edit-icon {
    width: 35px;
    margin-left: 10px;
    align-self: center;
}

.AdminReadResource-Header {
    justify-content: space-between;
}

.AdminEditResource-SaveSection {
    background-color: $primary;
    justify-content: space-between;
    align-items: center;
    height: 75px;

    h5 {
        color: $secondary;
        margin: 0px 35px;
        font-weight: bold;
    }
}

.AdminEditResource-SaveAlert {
    position: fixed;
    top: 0;
    right: 0;

    button svg {
        color: $secondary;
    }
}

.AdminEditResource-ServiceHeader {
    justify-content: space-between;
    align-items: center;

    h6 {
        margin: 0px;
        margin-left: 5px;
        padding: 5px 10px;
        border-radius: 15px;
        color: $secondary;
        background-color: $delete;
    }

    button:active, button:focus {
        outline: 0;
    }
}