/* The side navigation menu */
.MobileNav {
    height: 100%;
    /* 100% Full-height */
    width: 0;
    /* 0 width - change this with JavaScript */
    position: fixed;
    /* Stay in place */
    z-index: 1;
    /* Stay on top */
    top: 0;
    /* Stay at the top */
    left: 0;
    background-color: $primary;
    /* Black*/
    overflow-x: hidden;
    /* Disable horizontal scroll */
    padding-top: 60px;
    /* Place content 60px from the top */
    transition: 0.5s;
    /* 0.5 second transition effect to slide in the sidenav */

    a,
    button {
        padding: 8px 8px 8px 32px;
        text-decoration: none;
        font-size: 25px;
        color: $secondary;
        display: block;
        transition: 0.3s;
    }

    button:active, button:focus {
        outline: none;
    }

    a:hover {
        color: $primary
    }
}

.MobileNav-Link {
    transition: 0.3s;
}

#MobileNav-ExitBtn {
    width: 20px;
    position: absolute;
    top: 25px;
    right: 25px;
    font-size: 36px;
    margin-left: 50px;
}

#MobileNav-Logo {
    position: absolute;
    width: 200px;
    bottom: 30px;
    left: 50%;
    transform: translate(-50%, 0);
    margin-left: auto;
    margin-right: auto;
}

#MobileNav-Menu {
    width: 35px;
    height: 25px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
// #main {
//     transition: margin-left .5s;
//     padding: 20px;
// }

/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
    .sidenav {
        padding-top: 15px;
    }

    .sidenav a {
        font-size: 18px;
    }
}