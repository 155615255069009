* {
  color: #000000;
  box-sizing: border-box;
}

html body {
  font-family: Lato;
  margin: 0px;
  padding: 0px;
}

h1 {
  font-family: Poppins;
  font-weight: 700;
}

h2 {
  font-weight: 700;
}

h3 {
  font-family: Poppins;
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 3;
  overflow: hidden;
}

h5 {
  font-family: Lato;
  font-weight: 500;
}

h6 {
  font-size: 14px;
}

button {
  background: none;
  border: none;
}

a:hover {
  text-decoration: none;
}

@media (max-width: 700px) {
  h1 {
    font-size: 25px;
  }
}
.container-page {
  margin: 35px;
  display: flex;
  flex-direction: column;
}

@media (max-width: 1000px) {
  .container-page {
    margin: 20px;
  }
}
.subcontainer-page {
  padding: 10px 0px;
  margin: 10px 0px;
}

.container-card {
  margin: 0px 15px;
  padding: 0px 15px;
}

.subcontainer-card {
  margin: 20px 0px;
  padding: 0px 5px;
}

.subcontainer-mobilecard {
  margin-top: 0px;
  margin-bottom: 0px;
  width: 100%;
}

.flex-row {
  display: flex;
  flex-direction: row;
}

.flex-col {
  display: flex;
  flex-direction: column;
  flex-wrap: wrap;
}

.tagcontainer-page {
  display: flex;
  margin: 15px 5px 0px 0px;
}

.tagcontainer-card {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
}

@media (max-width: 1000px) {
  .tagcontainer-page {
    flex-wrap: wrap;
  }
}
.secondary-text {
  color: #FFFFFF;
  margin-left: 30%;
}

.Discover-searchBox-root {
  align-self: center;
  justify-self: center;
  margin-bottom: 20px;
  width: 350px;
  background-color: #fff;
  border-radius: 20px;
  margin-top: 40px;
}

.Refinement-Container {
  margin: 20px;
}

.refinement-Root {
  background-color: #7E41FF;
  color: #FFFFFF;
  width: 250px;
  border-radius: 15px;
  padding: 7px 0px;
}
.refinement-Root h5 {
  color: #FFFFFF;
  text-align: center;
}
.refinement-Root h4 {
  color: #FFFFFF;
  margin: 15px;
}

.refinement-ShowMore {
  color: #7E41FF;
  background-color: #FFFFFF;
  border: none;
  border-radius: 5px;
  margin: 15px;
}

.refinement-Item {
  color: #FFFFFF;
  list-style: none;
  margin: 5px 0px;
}

.Discover-Refinements {
  display: flex;
  flex-direction: column;
  /* Safari */
  position: sticky;
}

.Discover-MobileRefinements {
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  margin-top: 20px;
  margin-bottom: 20px;
  width: 100%;
  overflow-x: scroll;
}

.Refinement-MobileContainer {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px 10px;
  border-radius: 20px;
  padding: 10px;
  white-space: nowrap;
}
.Refinement-MobileContainer h5 {
  text-align: center;
  margin-bottom: 0px;
}

@media (max-width: 1000px) {
  .Refinement-MobileContainer h5 {
    font-size: 18px;
  }
}
.gallery {
  justify-content: space-around;
  flex-wrap: wrap;
}

.gallery-hits {
  display: flex;
  align-items: center;
  margin-left: auto;
  margin-right: auto;
}

.card-large {
  position: relative;
  border-radius: 20px;
  border-collapse: separate;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 15px;
  margin-bottom: 35px;
  width: 700px;
  height: 350px;
  flex-wrap: wrap;
}
.card-large h3 {
  font-size: 25px;
  font-weight: 600;
  text-align: left;
}
.card-large p {
  display: -webkit-box;
  -webkit-box-orient: vertical;
  -webkit-line-clamp: 5;
  overflow: hidden;
}

.card-small {
  position: relative;
  border-radius: 20px;
  border-collapse: separate;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 15px;
  margin-bottom: 35px;
  width: 500px;
  height: 350px;
  flex-shrink: 0;
}
.card-small h3 {
  padding-bottom: 5px;
  font-size: 25px;
  font-weight: 600;
  text-align: center;
}

.card-category {
  position: relative;
  border-radius: 20px;
  border-collapse: separate;
  box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin: 15px;
  margin-bottom: 35px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background-color: #7E41FF;
  width: 20vw;
  height: 210px;
  min-width: 190px;
}
.card-category div {
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: #FFFFFF;
  width: 110px;
  height: 110px;
  border-radius: 40%;
  margin: 20px;
}
.card-category h1 {
  font-size: 60px;
}
.card-category h5 {
  color: #FFFFFF;
  margin-bottom: 30px;
  margin-left: 10px;
  margin-right: 10px;
}

.card-expand {
  background-color: #7E41FF;
  border: #7E41FF;
  justify-content: center;
  align-items: center;
}
.card-expand h3 {
  color: #FFFFFF;
  text-align: center;
}

.card-small:hover, .card-large:hover, .card-category:hover, .card-category:active {
  box-shadow: 0 0 0 3px #7E41FF;
}

.card-col {
  width: 50%;
  height: 65%;
}

.card-parent-large {
  background-color: #7E41FF;
  color: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  height: 50px;
  padding: 14px 20px;
  margin-bottom: -5px;
  width: 100%;
  font-size: 18px;
}

.card-parent-small {
  background-color: #7E41FF;
  color: #FFFFFF;
  border-top-left-radius: 20px;
  border-top-right-radius: 20px;
  position: relative;
  height: 70px;
  z-index: 1;
  text-align: center;
  margin-bottom: -10px;
  padding: 0px -15px;
  font-size: 18px;
}

.card-content {
  padding: 0px 10px;
  width: 100%;
}

.card-service {
  list-style-type: disc;
  list-style-position: inside;
  text-indent: -1.4em;
  padding-left: 1em;
}

@media (max-width: 1000px) {
  .card-small {
    margin: 15px 5px;
    width: 310px;
    height: 300px;
  }
  .card-small h3 {
    font-size: 20px;
  }
  .card-small p {
    font-size: 15px;
  }
  .card-large {
    width: 100%;
    height: auto;
    flex-wrap: wrap;
    margin: 0px;
    margin-bottom: 20px;
    align-items: flex-start;
  }
  .card-large h3 {
    font-size: 22px;
    font-weight: 600;
    text-align: left;
    margin-bottom: 15px;
    margin-top: 20px;
  }
  .card-large h6 {
    font-size: 15px;
    margin-bottom: 15px;
  }
  .card-large p {
    font-size: 15px;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 5;
    overflow: hidden;
  }
  .card-category {
    width: 8vw;
    height: 150px;
    min-width: 150px;
    margin: 5px;
  }
  .card-category h1 {
    font-size: 40px;
    margin-bottom: 0px;
  }
  .card-category h5 {
    font-size: 17px;
    margin-bottom: 15px;
  }
  .card-category div {
    margin: 15px;
    height: 80px;
    width: 80px;
  }
  .card-parent-large {
    height: 45px;
    font-weight: bold;
    font-size: 13px;
  }
}
.header-underline-text {
  border-bottom: 2px solid #000000;
}

.header-underline-primary {
  border-bottom: 2px solid #7E41FF;
  color: #7E41FF;
}

.banner-edit {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #FF7A00;
  margin-bottom: 20px;
}
.banner-edit a {
  position: absolute;
  left: 35px;
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 18px;
}
.banner-edit a:hover {
  text-decoration: underline;
}
.banner-edit h3 {
  color: #FFFFFF;
  text-transform: bold;
  margin: 15px;
}
.banner-edit img {
  align-self: center;
  justify-self: center;
  margin-left: 8px;
}

.banner-read {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: #17A600;
  margin-bottom: 20px;
}
.banner-read a {
  position: absolute;
  left: 35px;
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 18px;
}
.banner-read a:hover {
  text-decoration: underline;
}
.banner-read h3 {
  color: #FFFFFF;
  text-transform: bold;
  margin: 15px;
}
.banner-read img {
  align-self: center;
  justify-self: center;
  margin-left: 8px;
}

.banner-warning {
  align-items: center;
  justify-content: center;
  display: flex;
  background-color: black;
  flex-wrap: none;
  padding: 0px 35px;
  justify-content: space-between;
  position: fixed;
  bottom: 0px;
  z-index: 1000;
  width: 100%;
  padding-bottom: 10px;
}
.banner-warning a {
  position: absolute;
  left: 35px;
  margin-bottom: 0;
  color: #FFFFFF;
  font-size: 18px;
}
.banner-warning a:hover {
  text-decoration: underline;
}
.banner-warning h3 {
  color: #FFFFFF;
  text-transform: bold;
  margin: 15px;
}
.banner-warning img {
  align-self: center;
  justify-self: center;
  margin-left: 8px;
}
.banner-warning h4 {
  color: #FFFFFF;
  margin: 20px 0px;
}
.banner-warning p {
  color: #FFFFFF;
}
.banner-warning button, .banner-warning button:active, .banner-warning button:hover, .banner-warning button:focus {
  position: fixed;
  bottom: 70px;
  right: -40px;
  outline: none;
}
.banner-warning button img, .banner-warning button:active img, .banner-warning button:hover img, .banner-warning button:focus img {
  width: 20px;
}

.banner-warning-explanation {
  width: 50%;
}

.banner-warning-button {
  display: flex;
}
.banner-warning-button button, .banner-warning-button button:hover, .banner-warning-button button:active, .banner-warning-button button:focus {
  padding: 25px;
  margin-right: 40px !important;
}

.MuiCircularProgress-root {
  position: absolute;
  top: 50%;
  left: 50%;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
  background-color: #7E41FF;
  color: #FFFFFF;
  border: 2px solid #7E41FF;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
}
.btn-dark h1, .btn-dark h2, .btn-dark h3, .btn-dark h4, .btn-dark h5,
.btn-dark:hover h1,
.btn-dark:hover h2,
.btn-dark:hover h3,
.btn-dark:hover h4,
.btn-dark:hover h5,
.btn-dark:focus h1,
.btn-dark:focus h2,
.btn-dark:focus h3,
.btn-dark:focus h4,
.btn-dark:focus h5,
.btn-dark:active h1,
.btn-dark:active h2,
.btn-dark:active h3,
.btn-dark:active h4,
.btn-dark:active h5 {
  color: #FFFFFF;
}

.btn-light,
.btn-light:hover,
.btn-light:focus,
.btn-light:active {
  background-color: #FFFFFF;
  color: #7E41FF;
  border: 2px solid #7E41FF;
  padding: 5px 10px;
  margin-right: 10px;
  border-radius: 15px;
  outline: none;
  box-shadow: none;
}
.btn-light h1, .btn-light h2, .btn-light h3, .btn-light h4, .btn-light h5,
.btn-light:hover h1,
.btn-light:hover h2,
.btn-light:hover h3,
.btn-light:hover h4,
.btn-light:hover h5,
.btn-light:focus h1,
.btn-light:focus h2,
.btn-light:focus h3,
.btn-light:focus h4,
.btn-light:focus h5,
.btn-light:active h1,
.btn-light:active h2,
.btn-light:active h3,
.btn-light:active h4,
.btn-light:active h5 {
  color: #7E41FF;
}

.primary-color {
  color: #7E41FF;
}

.secondary-color {
  color: #FFFFFF;
}

.NavBar {
  display: flex;
  align-items: center;
  justify-content: space-between;
  font-weight: 200;
  padding: 35px 20px;
  width: 100%;
  height: 100px;
}

.NavBar-Links {
  display: flex;
  justify-self: center;
}

.DesktopNavbar {
  align-items: center;
}

#NavBar-TryCompassBtn {
  margin-left: 20px;
  font-weight: bolder;
  padding: 15px 20px;
}

.navLink {
  margin: 0px 15px;
  text-decoration: none;
  font-weight: bolder;
  font-size: 20px;
}

.cl-userButtonAvatarBox {
  margin: 0px 15px;
}

.cl-userButtonAvatarBox:active {
  outline: none;
}

.navLink:hover {
  color: #FFFFFF;
  cursor: pointer;
}

.navLink:focus {
  color: #FFFFFF;
}

.CompassLogo {
  width: 200px;
  height: 40px;
}

@media (max-width: 700px) {
  .NavBar {
    padding: 25px 10px;
  }
}
.large-tag {
  margin-right: 15px;
  margin-bottom: 0px;
  border-radius: 10px;
  font-weight: bold;
  padding: 5px 15px;
}

.small-tag {
  padding: 4px 8px;
  margin-right: 5px;
}

.type-tag {
  text-transform: uppercase;
  background-color: #7E41FF;
  border: solid 3px #7E41FF;
  color: #FFFFFF;
  border-radius: 20px;
}

.affiliation-tag {
  text-transform: capitalize;
  border: solid 3px;
  border-radius: 20px;
}

.services-tag {
  box-shadow: 0px -4px 4px rgba(0, 0, 0, 0.1);
  color: #000000;
  border-top-right-radius: 20px;
  border-top-left-radius: 20px;
  width: 100%;
  margin: 10px 0px;
  padding: 9px 8px;
  font-size: 17px;
  font-weight: bolder;
  text-align: center;
}

.website-tag {
  border: solid 3px #7E41FF;
  border-radius: 20px;
  color: #7E41FF;
}
.website-tag img {
  margin: 0px;
}

@media (max-width: 800px) {
  .small-tag {
    font-size: 12px;
  }
  .large-tag {
    font-size: 17px;
    margin-right: 10px;
    margin-bottom: 10px;
    padding: 5px;
  }
  .services-tag {
    font-size: 20px !important;
  }
}
.Service-Text {
  font-size: 18px;
  display: flex;
}
.Service-Text li {
  margin-right: 7px;
  text-decoration: none;
}

.Service-Link {
  text-decoration-color: #000000;
  color: #7E41FF;
}

#linkIcon {
  margin-left: 7px;
}

.ResourceCard-Link {
  text-align: center;
  font-weight: bold;
  text-decoration: underline;
}

.SearchBar {
  width: 500px;
  padding: 10px;
  border-radius: 20px;
  background-color: #FFFFFF;
  margin: 20px 0px;
}
.SearchBar img {
  width: 20px;
}
.SearchBar input, .SearchBar input:focus {
  border: none;
  outline: none;
  width: 90%;
  margin-left: 5px;
}

.SearchBar-top {
  position: absolute;
  top: 0px;
  left: 35%;
}

.SearchBar-MobileContainer {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #7E41FF;
  height: 70px;
}

.SearchBar-mobilepos {
  position: relative;
  margin-top: -20px;
}

.SearchBar-InsideBtn, .SearchBar-InsideBtn:hover {
  margin: 0px;
}

.SearchBar-OutsideBtn {
  display: none;
}

@media (max-width: 800px) {
  .SearchBar-OutsideBtn, .SearchBar-OutsideBtn:hover, .SearchBar-OutsideBtn:active {
    display: block;
    border: none;
    width: 100%;
    align-self: center;
    margin: 0px;
    margin-top: 20px;
  }
  .SearchBar-InsideBtn {
    display: none;
  }
  .SearchBar {
    width: 300px;
  }
}
.Emergency {
  background-color: #FCE3DA;
  padding: 20px;
  border-radius: 44px;
  max-height: 450px;
  margin: 15px 0px;
}
.Emergency h3 {
  color: #DE1214;
  font-weight: bold;
}

.Emergency-Gallery {
  justify-content: center;
  align-items: center;
}

.Emergency-Card {
  background-color: #FFFFFF;
  margin: 20px;
  width: 300px;
  height: 240px;
  border-radius: 30px;
  align-items: center;
  justify-content: center;
}
.Emergency-Card h1 {
  font-size: 65px;
  margin: 15px 0px;
  color: #DE1214;
}
.Emergency-Card h5, .Emergency-Card a {
  color: #DE1214;
}

/* The side navigation menu */
.MobileNav {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background-color: #7E41FF;
  /* Black*/
  overflow-x: hidden;
  /* Disable horizontal scroll */
  padding-top: 60px;
  /* Place content 60px from the top */
  transition: 0.5s;
  /* 0.5 second transition effect to slide in the sidenav */
}
.MobileNav a,
.MobileNav button {
  padding: 8px 8px 8px 32px;
  text-decoration: none;
  font-size: 25px;
  color: #FFFFFF;
  display: block;
  transition: 0.3s;
}
.MobileNav button:active, .MobileNav button:focus {
  outline: none;
}
.MobileNav a:hover {
  color: #7E41FF;
}

.MobileNav-Link {
  transition: 0.3s;
}

#MobileNav-ExitBtn {
  width: 20px;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
}

#MobileNav-Logo {
  position: absolute;
  width: 200px;
  bottom: 30px;
  left: 50%;
  transform: translate(-50%, 0);
  margin-left: auto;
  margin-right: auto;
}

#MobileNav-Menu {
  width: 35px;
  height: 25px;
}

/* Style page content - use this if you want to push the page content to the right when you open the side navigation */
/* On smaller screens, where height is less than 450px, change the style of the sidenav (less padding and a smaller font size) */
@media screen and (max-height: 450px) {
  .sidenav {
    padding-top: 15px;
  }
  .sidenav a {
    font-size: 18px;
  }
}
.MobileRefinement {
  height: 100%;
  /* 100% Full-height */
  width: 0;
  /* 0 width - change this with JavaScript */
  position: fixed;
  /* Stay in place */
  z-index: 1;
  /* Stay on top */
  top: 0;
  /* Stay at the top */
  left: 0;
  background-color: #7E41FF;
  /* Black*/
  overflow-x: hidden;
  overflow: hidden;
  /* Disable horizontal scroll */
  transition: 0.3s;
  padding-top: 60px;
  /* Place content 60px from the top */
  /* 0.5 second transition effect to slide in the sidenav */
}
.MobileRefinement h3, .MobileRefinement button {
  display: block;
}
.MobileRefinement h3 {
  width: 300px;
  padding: 8px 8px 8px 32px;
  color: #FFFFFF;
}

.MobileRefinement-Root {
  background-color: #7E41FF;
  color: #FFFFFF;
  width: 250px;
  border-radius: 15px;
}

.MobileRefinement-ShowMore {
  color: #7E41FF;
  background-color: #FFFFFF;
  border: none;
  border-radius: 5px;
  margin: 8px 8px 8px 32px;
}

#MobileRefinement-CloseBtn, #MobileRefinement-CloseBtn:active, #MobileRefinement-CloseBtn:focus, #MobileRefinement-CloseBtn:hover {
  width: 20px;
  position: absolute;
  top: 25px;
  right: 25px;
  font-size: 36px;
  margin-left: 50px;
  outline: none;
  box-shadow: none;
  border: none;
}

.Footer {
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 175px;
  background-color: #F9F7F3;
}
.Footer p {
  color: #7E41FF;
}

.Footer-Text {
  display: flex;
  flex-direction: column;
  width: 300px;
  font-size: 12px;
  margin-right: 30px;
  font-weight: 300;
}

.Footer-Links {
  display: flex;
  flex-direction: row;
  font-weight: 300;
}

.Footer-Link, .Footer-Link:hover, .Footer-Link:active, .Footer-Link:focus {
  text-transform: uppercase;
  margin: 0px 10px;
  color: #7E41FF;
}

#Footer-Logo {
  width: 150px;
  margin-bottom: 10px;
}

.COSTDIV {
  display: flex;
}

@media (max-width: 800px) {
  .Footer {
    flex-direction: column;
    height: 275px;
  }
  .Footer-Text {
    margin-bottom: 20px;
  }
}
.ResourceContent-LearnMore {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
}

.ResourceContent-LearnMoreItem {
  display: flex;
  justify-content: center;
  align-items: center;
  border: solid 3px #7E41FF;
  text-decoration: none;
  border-radius: 10px;
  padding: 10px;
  margin: 10px 20px 0px 0px;
}
.ResourceContent-LearnMoreItem h3 {
  margin-right: 10px;
  margin-bottom: 0px;
}
.ResourceContent-LearnMoreItem h4 {
  margin-right: 10px;
  margin-bottom: 0px;
  color: #7E41FF;
}
.ResourceContent-LearnMoreItem b, .ResourceContent-LearnMoreItem a {
  color: #7E41FF;
}
.ResourceContent-LearnMoreItem h4::first-letter {
  text-transform: uppercase;
}

.ResourceContent-LearnMoreItem:hover, .ResourceContent-LearnMoreItem:active {
  background-color: #7E41FF;
  cursor: pointer;
}
.ResourceContent-LearnMoreItem:hover h3, .ResourceContent-LearnMoreItem:hover a, .ResourceContent-LearnMoreItem:hover b, .ResourceContent-LearnMoreItem:hover h4, .ResourceContent-LearnMoreItem:active h3, .ResourceContent-LearnMoreItem:active a, .ResourceContent-LearnMoreItem:active b, .ResourceContent-LearnMoreItem:active h4 {
  color: #FFFFFF;
}

.ResourceContent-Parent {
  background-color: #7E41FF;
  color: #FFFFFF;
  border-bottom-left-radius: 20px;
  border-bottom-right-radius: 20px;
  height: 50px;
  padding: 14px 30px;
  margin-bottom: -5px;
  width: 100%;
  font-size: 16px;
}
.ResourceContent-Parent h5 {
  font-weight: bold;
}
.ResourceContent-Parent b {
  color: #FFFFFF;
}

.ResourceContent-Services {
  max-height: 450px;
  margin-top: 20px;
}

.ResourceContent-Selected {
  background-color: #7E41FF;
  color: #FFFFFF;
}

.ResourceContent-Body {
  line-height: 1.5;
}

@media (max-width: 1000px) {
  .ResourceContent-Services {
    max-height: none;
  }
}
@media (max-width: 800px) {
  .ResourceContent-LearnMoreItem {
    padding: 5px 10px;
  }
  .ResourceContent-LearnMoreItem img {
    width: 25px;
  }
  .ResourceContent-LearnMoreItem h4 {
    font-size: 19px;
  }
  .Service-Text {
    font-size: 16px;
  }
  .ResourceContent h2 {
    font-size: 26px;
  }
  .ResourceContent h5 {
    font-size: 17px;
  }
  .ResourceContent-Services {
    height: auto;
  }
}
#edit-text {
  align-self: center;
  color: #FF7A00;
  font-weight: bold;
  margin-bottom: 0;
  margin-right: 5px;
}

#edit-icon {
  width: 35px;
  margin-left: 10px;
  align-self: center;
}

.AdminReadResource-Header {
  justify-content: space-between;
}

.AdminEditResource-SaveSection {
  background-color: #7E41FF;
  justify-content: space-between;
  align-items: center;
  height: 75px;
}
.AdminEditResource-SaveSection h5 {
  color: #FFFFFF;
  margin: 0px 35px;
  font-weight: bold;
}

.AdminEditResource-SaveAlert {
  position: fixed;
  top: 0;
  right: 0;
}
.AdminEditResource-SaveAlert button svg {
  color: #FFFFFF;
}

.AdminEditResource-ServiceHeader {
  justify-content: space-between;
  align-items: center;
}
.AdminEditResource-ServiceHeader h6 {
  margin: 0px;
  margin-left: 5px;
  padding: 5px 10px;
  border-radius: 15px;
  color: #FFFFFF;
  background-color: #D70202;
}
.AdminEditResource-ServiceHeader button:active, .AdminEditResource-ServiceHeader button:focus {
  outline: 0;
}

.Discover-SearchBanner {
  height: 500px;
  padding: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(89.88deg, rgba(158, 123, 247, 0.71) -91.34%, #7E41FF 8.89%, #4643E7 55.95%, #183D74 123.64%);
}
.Discover-SearchBanner h1 {
  font-size: 4rem;
  letter-spacing: -0.02em;
  font-family: Montserrat;
  color: #FFFFFF;
  font-weight: 400;
}
.Discover-SearchBanner h2 {
  font-family: Montserrat;
  color: #FFFFFF;
  font-weight: 400;
}
.Discover-SearchBanner b {
  color: #FFFFFF;
}
.Discover-SearchBanner div {
  justify-self: center;
  align-self: center;
}
@media (max-width: 1171px) {
  .Discover-SearchBanner h1 {
    font-size: 2.7rem;
  }
}
@media (max-width: 700px) {
  .Discover-SearchBanner {
    height: 375px;
    padding: 10px;
  }
  .Discover-SearchBanner h1 {
    font-size: 1.7rem;
  }
}

.Discover-SearchAdBanner {
  height: 500px;
  padding: 50px;
  justify-content: center;
  align-items: center;
  text-align: center;
  background: linear-gradient(89.88deg, #E6AF2E -91.32%, #E6AF2E -19.67%, #7E41FF 75.51%, #7E41FF 123.64%);
}
.Discover-SearchAdBanner h1 {
  font-size: 4rem;
  letter-spacing: -0.02em;
  font-family: Montserrat;
  color: #FFFFFF;
  font-weight: 400;
}
.Discover-SearchAdBanner h2 {
  font-family: Montserrat;
  color: #FFFFFF;
  font-weight: 400;
}
.Discover-SearchAdBanner b {
  color: #FFFFFF;
}
.Discover-SearchAdBanner div {
  justify-self: center;
  align-self: center;
}
@media (max-width: 1171px) {
  .Discover-SearchAdBanner h1 {
    font-size: 2.7rem;
  }
}
@media (max-width: 700px) {
  .Discover-SearchAdBanner {
    height: 375px;
    padding: 10px;
  }
  .Discover-SearchAdBanner h1 {
    font-size: 1.7rem;
  }
}
.Discover-SearchAdBanner button, .Discover-SearchAdBanner button:focus {
  font-size: 30px;
  margin-top: 50px;
  width: 190px;
}

.Discover-Categories {
  margin: 35px 0px 0px 35px;
}
.Discover-Categories h1 {
  color: #7E41FF;
  font-weight: bold;
}
.Discover-Categories h5 {
  color: #7E41FF;
  text-decoration: underline;
}
.Discover-Categories .Discover-CategoryCards {
  overflow-y: scroll;
}

.Discover-TypeCards {
  justify-content: center;
}
.Discover-TypeCards h1 {
  color: #7E41FF;
}

.Discover-SearchContainer {
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
}

#search-icon {
  height: 30px;
  margin-right: 15px;
  margin-top: 15px;
}

.Discover-MobileTagline {
  display: none;
}

@media (max-width: 1171px) {
  .Discover-TypeCards {
    flex-wrap: wrap;
    margin-bottom: 20px;
  }
}
@media (max-width: 700px) {
  .Discover-Categories {
    margin: 20px 0px 0px 20px;
  }
  .Discover-DesktopTagline {
    display: none;
  }
  .Discover-MobileTagline {
    display: block;
  }
}
.About {
  justify-content: center;
  align-items: center;
  color: #7E41FF;
  width: 100%;
  max-width: 1800px;
  margin: 0px;
}

.About-HeroText {
  font-weight: 300;
  text-align: center;
}

.About-Subcontainer {
  justify-content: center;
  align-items: center;
}
.About-Subcontainer h1 {
  font-family: Montserrat;
}
.About-Subcontainer h3 {
  -webkit-box-orient: none;
  -webkit-line-clamp: 10;
  overflow: visible;
}

.About-ProblemStatement {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: linear-gradient(89.88deg, rgba(158, 123, 247, 0.71) -91.34%, #7E41FF 8.89%, #4643E7 55.95%, #183D74 123.64%);
  padding: 50px 0px;
  width: 100%;
}
.About-ProblemStatement div {
  width: 50%;
}
.About-ProblemStatement h3 {
  margin-right: 20px;
  color: #FFFFFF;
  font-weight: 300;
}

.About-Students {
  padding: 50px 0px;
}

.About-StudentList {
  justify-content: space-between;
  list-style: none;
  padding: 0px;
  margin-left: 20px;
  width: 100%;
}
.About-StudentList li {
  font-size: 20px;
  text-indent: -25px;
  margin-left: 10px;
  margin-bottom: 25px;
}

.About-StudentListText {
  align-items: flex-start;
  padding-right: 70px;
  margin-left: 40px;
}

.About-StudentListImg {
  justify-content: flex-end;
  width: 50%;
}

.About-Transition {
  background: linear-gradient(89.88deg, #E6AF2E -91.32%, #E6AF2E -19.67%, #7E41FF 75.51%, #7E41FF 123.64%);
  text-align: center;
  width: 100%;
  height: 400px;
}

.About-Product {
  margin: 0px 35px;
  justify-content: center;
  align-items: center;
}
.About-Product h1 {
  margin-bottom: 40px;
  text-align: center;
}
.About-Product h4 {
  margin: 15px 45px;
  text-align: center;
}

.About-ProductText {
  margin: 20px 0px;
  width: 80%;
}

.About-ProductTiles {
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-template-rows: repeat(5, 1fr);
  grid-column-gap: 0px;
  grid-row-gap: 0px;
  width: 100%;
  height: 875px;
}
.About-ProductTiles div {
  display: grid;
  text-align: center;
  background-color: #7E41FF;
  border-radius: 28px;
  margin: 5px;
  padding: 20px;
}
.About-ProductTiles div img {
  align-self: center;
  justify-self: center;
}
.About-ProductTiles div h3 {
  margin-top: 20px;
  align-self: flex-end;
}

.About-ProductTile-Place, .About-ProductTile-Search, .About-ProductTile-Curated {
  justify-content: flex-start;
}

.About-ProductTile-Place {
  grid-area: 1/1/4/4;
}

.About-ProductTile-Search {
  grid-area: 4/1/6/4;
}

.About-ProductTile-Curated {
  grid-area: 1/4/6/6;
}

.About-Action {
  justify-content: center;
  align-items: center;
  text-align: center;
  width: 100%;
  height: 500px;
  background-image: url("contourbackground.png");
  margin-bottom: 0px;
}
.About-Action button, .About-Action button:active, .About-Action button:hover, .About-Action button:focus {
  font-size: 30px;
  padding: 10px 20px;
  border-radius: 30px;
  margin-bottom: 30px;
}
.About-Action button a, .About-Action button:active a, .About-Action button:hover a, .About-Action button:focus a {
  text-decoration: none;
  color: #7E41FF;
}
.About-Action h1 {
  margin-bottom: 60px;
}
.About-Action h3 {
  color: #FFFFFF;
  font-style: italic;
}

.About-bold {
  font-weight: bold;
}

#About-DesktopPhone {
  width: 650px;
}

#About-Maze {
  width: 45%;
}

#About-StressedStudent {
  width: 40%;
  margin-right: 40px;
}

#About-Tablet {
  width: 650px;
  margin-bottom: 50px;
}

#About-StackedCards {
  width: 80%;
}

#About-StackedSearches {
  width: 70%;
}

#About-PhoneView {
  width: 75%;
}

@media (max-width: 1200px) {
  #About-DesktopPhone {
    width: 550px;
  }
  #About-Tablet {
    width: 550px;
  }
  .About-ProductTiles {
    height: 750px;
  }
}
@media (max-width: 750px) {
  #About-DesktopPhone, #About-Tablet {
    width: 80%;
  }
  .About-ProblemStatement {
    display: grid;
    flex-direction: column;
  }
  .About-ProblemStatement h3 {
    margin: 0px;
  }
  .About-ProblemStatement div {
    justify-self: center;
    text-align: center;
    width: 80%;
    margin-top: 40px;
  }
  .About-Students {
    display: grid;
    flex-direction: column;
  }
  .About-StudentList li {
    font-size: 15px;
  }
  .About-Product {
    margin: 0px 15px;
  }
  .About-ProductText {
    width: 95%;
  }
  .About-ProductText h4 {
    font-size: 20px;
    margin: 5px;
  }
  .About-ProductTiles {
    display: flex;
    flex-direction: column;
    height: auto;
  }
  #About-PhoneView {
    width: auto;
    height: 400px;
  }
  .About-ProductTile-Place h3, .About-ProductTile-Search h3, .About-ProductTile-Curated h3 {
    font-size: 20px;
  }
  #About-StressedStudent {
    justify-self: center;
    width: 70%;
  }
  #About-Maze {
    justify-self: flex-start;
    width: 70%;
  }
  .About-Action button {
    font-size: 20px;
  }
  .About-Action h3 {
    font-size: 18px;
  }
}
.Team {
  display: grid;
}
.Team h1 {
  justify-self: flex-start;
}

.Team-Headshots {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  margin: 50px 0px;
  justify-content: center;
  align-items: center;
}

.Team-Headshot {
  width: 33%;
  transition: transform 0.2s;
}
.Team-Headshot img {
  width: 90%;
  border-radius: 10px;
  margin-bottom: 15px;
  min-width: 200px;
}
.Team-Headshot h4 {
  margin-bottom: 3px;
}
.Team-Headshot h4, .Team-Headshot h5 {
  text-align: left;
}

.Team-Headshot:hover {
  transform: scale(1.05);
}

@media (max-width: 750px) {
  .Team-Headshots {
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .Team-Headshot {
    width: 50%;
    margin-bottom: 50px;
  }
}
.Contact-HeroText {
  font-weight: 300;
  color: #7E41FF;
}/*# sourceMappingURL=main.css.map */