// Custom styling for default HTML tags
* {
    color: $text;
    box-sizing: border-box;
}

html {
    body {
        font-family: $base;
        margin: 0px;
        padding: 0px;
    }
}

h1 {
    font-family: $header;
    font-weight: 700;
}

h2 {
    font-weight: 700;
}

h3 {
    font-family: $header;
    display: -webkit-box;
    -webkit-box-orient: vertical;
    -webkit-line-clamp: 3;
    overflow: hidden;
}

h5 {
    font-family: $base;
    font-weight: 500;
}

h6 {
    font-size: 14px;
}

button {
    background: none;
    border: none;
}

a:hover {
    text-decoration: none;
}

@media(max-width: 700px) {
    h1 {
        font-size: 25px;
    }
}