.Team {
    display: grid;
    h1 {
        justify-self: flex-start;
    }
}

.Team-Headshots {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    margin: 50px 0px;

    justify-content: center;
    align-items: center;
}

.Team-Headshot {
    width: 33%;
    transition: transform .2s;
    img {
        width: 90%;
        border-radius: 10px;
        margin-bottom: 15px;
        min-width: 200px;
    }

    h4 {
        margin-bottom: 3px;
    }

    h4, h5 {
        text-align: left;
    }
}

.Team-Headshot:hover {
    transform: scale(1.05);
}

@media (max-width: 750px) {
    .Team-Headshots {
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }

    .Team-Headshot {
        width: 50%;
        margin-bottom: 50px;
    }
}