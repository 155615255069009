.Emergency {
    background-color: $emergency-background;
    padding: 20px;
    border-radius: 44px;
    max-height: 450px;

    h3 {
        color: $emergency;
        font-weight: bold;
    }

    margin: 15px 0px;
}

.Emergency-Gallery {
    justify-content: center;
    align-items: center;
}

.Emergency-Card {
    background-color: $secondary;
    margin: 20px;

    width: 300px;
    height: 240px;

    border-radius: 30px;

    align-items: center;
    justify-content: center;

    h1 {
        font-size: 65px;
        margin: 15px 0px;
        color: $emergency;
    }

    h5, a {
        color: $emergency;
    }

}