// Custom classes shared across multiple pages or components

// PAGE CONTAINERS /////////////////////////////////////////////////

// container-page: provides formatting for an entire page's content.
// Should be one of the first tags utilized when a new page is created, and should
// encapsulate entire page's content.
.container-page {
    margin: 35px;
    display: flex;
    flex-direction: column;
}

@media(max-width: 1000px) {
    .container-page {
        margin: 20px;
    }
}

// subcontainer-page: provides formatting for subsection within page.
// Should be used when page has multiple sections in it, where each
// section is enacpsulated by a span.
.subcontainer-page {
    padding: 10px 0px;
    margin: 10px 0px;
}

.container-card {
    margin: 0px 15px;
    padding: 0px 15px;
}

.subcontainer-card {
    margin: 20px 0px;
    padding: 0px 5px;
}

.subcontainer-mobilecard {
    margin-top: 0px;
    margin-bottom: 0px;
    width: 100%;
}

////////////////////////////////////////////////////////////////////
// FLEX CONTAINERS /////////////////////////////////////////////////
.flex-row {
    display: flex;
    flex-direction: row;
}

.flex-col {
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
}

// TAG CONTAINERS
.tagcontainer-page {
    display: flex;
    margin: 15px 5px 0px 0px;
}

.tagcontainer-card {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
}

@media (max-width: 1000px) {
    .tagcontainer-page {
        flex-wrap: wrap;
    }
}

////////////////////////////////////////////////////////////////////
// TEXT ///// //////////////////////////////////////////////////////
.secondary-text {
    color: $secondary;
    margin-left: 30%;
}

////////////////////////////////////////////////////////////////////
// SEARCH BOX //////////////////////////////////////////////////////
.Discover-searchBox-root {
    align-self: center;
    justify-self: center;
    margin-bottom: 20px;
    width: 350px;
    background-color: #fff;
    border-radius: 20px;
    margin-top: 40px;
}

// .searchBox-root {
//     align-self: center;
//     justify-self: center;
//     margin-bottom: 20px;
//     width: 400px;
//     background-color: #fff;
//     border-radius: 20px;
//     position: absolute;
//     top: 17px;
// }

// .searchBox-input {
//     width: 300px;
//     height: 40px;
//     margin: 10px;
//     border: none;
//     background-color: #fff;
//     padding: 5px;
// }

// .searchBox-input:focus {
//     outline: none;
// }

// .searchBox-submit {
//     display: none;
//     opacity: 0;
// }

////////////////////////////////////////////////////////////////////
// REFINEMENTS /////////////////////////////////////////////////////
.Refinement-Container {
    margin: 20px;
}

.refinement-Root {
    background-color: $primary;
    color: $secondary;
    width: 250px;
    border-radius: 15px;
    padding: 7px 0px;

    h5 {
        color: $secondary;
        text-align: center;
    }

    h4 {
        color: $secondary;
        margin: 15px;
    }
}


.refinement-ShowMore {
    color: #7E41FF;
    background-color: $secondary;
    border: none;
    border-radius: 5px;
    margin: 15px;
}

.refinement-Item {
    color: $secondary;
    list-style: none;
    margin: 5px 0px;
}

.Discover-Refinements {
    display: flex;
    flex-direction: column;
    position: -webkit-sticky;
    /* Safari */
    position: sticky;
}

.Discover-MobileRefinements {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    margin-top: 20px;
    margin-bottom: 20px;

    width: 100%;
    overflow-x: scroll;
}

.Refinement-MobileContainer {
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 0px 10px;
    // background-color: $secondary;
    // color: $primary;
    // border: solid $primary 3px;

    border-radius: 20px;
    padding: 10px;

    white-space: nowrap;

    h5 {
        text-align: center;
        margin-bottom: 0px;
    }
}

@media (max-width: 1000px) {
    .Refinement-MobileContainer {
        h5 {
            font-size: 18px;
        }
    }
}

////////////////////////////////////////////////////////////////////
// CARD GALLERY ////////////////////////////////////////////////////
.gallery {
    justify-content: space-around;
    flex-wrap: wrap;
}

.gallery-hits {
    display: flex;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
}

////////////////////////////////////////////////////////////////////
// CARD ////////////////////////////////////////////////////////////
@mixin card {
    position: relative;
    border-radius: 20px;
    border-collapse: separate;
    box-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
    margin: 15px;
    margin-bottom: 35px;
}

.card-large {
    @include card;
    width: 700px;
    height: 350px;
    flex-wrap: wrap;

    // Card header
    h3 {
        font-size: 25px;
        font-weight: 600;
        text-align: left;
    }

    p {
        display: -webkit-box;
        -webkit-box-orient: vertical;
        -webkit-line-clamp: 5;
        overflow: hidden;
    }
}

.card-small {
    @include card;
    width: 500px;
    height: 350px;

    flex-shrink: 0;

    // Card header
    h3 {
        padding-bottom: 5px;
        font-size: 25px;
        font-weight: 600;
        text-align: center;
    }
}

.card-category {
    @include card;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    background-color: $primary;

    width: 20vw;
    height: 210px;
    min-width: 190px;

    div {
        display: flex;
        justify-content: center;
        align-items: center;
        background-color: $secondary;

        width: 110px;
        height: 110px;
        border-radius: 40%;
        margin: 20px;
    }

    h1 {
        font-size: 60px;
    }

    h5 {
        color: $secondary;
        margin-bottom: 30px;
        margin-left: 10px;
        margin-right: 10px;
    }
}

.card-expand {
    background-color: $primary;
    border: $primary;
    justify-content: center;
    align-items: center;
    
    h3 {
        color: $secondary;
        text-align: center;
    }
}


.card-small:hover, .card-large:hover, .card-category:hover, .card-category:active {
    box-shadow: 0 0 0 3px $primary;
}

.card-col {
    width: 50%;
    height: 65%;
}

@mixin card-parent {
    background-color: $primary;
    color: $secondary;
    border-top-left-radius: 20px;
    border-top-right-radius: 20px;
}

.card-parent-large {
    @include card-parent;
    height: 50px;
    padding: 14px 20px;
    margin-bottom: -5px;
    width: 100%;

    font-size: 18px;
}

.card-parent-small {
    @include card-parent;
    position: relative;
    height: 70px;
    z-index: 1;
    text-align: center;
    margin-bottom: -10px;
    padding: 0px -15px;
    
    font-size: 18px;
}

.card-content {
    padding: 0px 10px;
    width: 100%;
}

.card-service {
    list-style-type: disc;
    list-style-position: inside;
    text-indent: -1.4em;
    padding-left: 1em;
}

@media(max-width: 1000px) {
    .card-small {
        margin: 15px 5px;
        width: 310px;
        height: 300px;

        h3 {
            font-size: 20px;
        }

        p {
            font-size: 15px;
        }
    }

    .card-large {
        width: 100%;
        height: auto;
        flex-wrap: wrap;
        margin: 0px;
        margin-bottom: 20px;

        align-items: flex-start;

        // Card header
        h3 {
            font-size: 22px;
            font-weight: 600;
            text-align: left;
            margin-bottom: 15px;
            margin-top: 20px;
        }

        h6 {
            font-size: 15px;
            margin-bottom: 15px;
        }

        p {
            font-size: 15px;
            display: -webkit-box;
            -webkit-box-orient: vertical;
            -webkit-line-clamp: 5;
            overflow: hidden;
        }
    }

    .card-category {
        width: 8vw;
        height: 150px;
        min-width: 150px;
        margin: 5px;

        h1 {
            font-size: 40px;
            margin-bottom: 0px;
        }

        h5 {
            font-size: 17px;
            margin-bottom: 15px;
        }

        div {
            margin: 15px;
            height: 80px;
            width: 80px;
        }
    }

    .card-parent-large {
        height: 45px;
        font-weight: bold;
        font-size: 13px;
    }
}

////////////////////////////////////////////////////////////////////
// MISC ////////////////////////////////////////////////////////////
.header-underline-text {
    border-bottom: 2px solid $text;
}

.header-underline-primary {
    border-bottom: 2px solid $primary;
    color: $primary;
}

@mixin banner {
    align-items: center;
    justify-content: center;
    display: flex;

    a {
        position: absolute;
        left: 35px;
        margin-bottom: 0;
        color: $secondary;
        font-size: 18px;
    }

    a:hover {
        text-decoration: underline;
    }

    h3 {
        color: $secondary;
        text-transform: bold;
        margin: 15px;
    }

    img {
        align-self: center;
        justify-self: center;
        margin-left: 8px;
    }
}

.banner-edit {
    @include banner;
    background-color: $edit;
    margin-bottom: 20px;
}

.banner-read {
    @include banner;
    background-color: $read;
    margin-bottom: 20px;
}

.banner-warning {
    @include banner;
    background-color: black;
    flex-wrap: none;
    padding: 0px 35px;
    justify-content: space-between;

    position: fixed;
    bottom: 0px;
    z-index: 1000;
    width: 100%;
    padding-bottom: 10px;

    h4 {
        color: $secondary;
        margin: 20px 0px;
    }

    p {
        color: $secondary;
    }

    button, button:active, button:hover, button:focus {
        position: fixed;
        bottom: 70px;
        right: -40px;
        outline: none;

        img {
            width: 20px;
        }
    }
}

.banner-warning-explanation {
    width: 50%;
}

.banner-warning-button {
    display: flex;
    button, button:hover, button:active, button:focus {
        padding: 25px;
        margin-right: 40px !important;
    }
}

.MuiCircularProgress-root {
    position: absolute;
    top: 50%;
    left: 50%;
}

.btn-dark,
.btn-dark:hover,
.btn-dark:focus,
.btn-dark:active {
    background-color: $primary;
    color: $secondary;
    border: 2px solid $primary;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 15px;
    outline: none;
    box-shadow: none;

    h1, h2, h3, h4, h5 {
        color: $secondary;
    }
}

.btn-light,
.btn-light:hover,
.btn-light:focus,
.btn-light:active {
    background-color: $secondary;
    color: $primary;
    border: 2px solid $primary;
    padding: 5px 10px;
    margin-right: 10px;
    border-radius: 15px;
    outline: none;
    box-shadow: none;

    h1, h2, h3, h4, h5 {
        color: $primary;
    }
}

.primary-color {
    color: $primary;
}

.secondary-color {
    color: $secondary;
}

/////////////////////////////////////////////////////////////