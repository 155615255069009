@mixin Discover-Banner {
    height: 500px;
    padding: 50px;
    justify-content: center;
    align-items: center;
    text-align: center;

    // Banner text
    h1 {
        font-size: 4rem;
        letter-spacing: -0.02em;
        font-family: $hero;
        color: $secondary;
        font-weight: 400;
    }

    h2 {
        font-family: $hero;
        color: $secondary;
        font-weight: 400;
    }

    // Bolded part of banner text
    b {
        color: $secondary;
    }

    div {
        justify-self: center;
        align-self: center;
    }

    @media (max-width: 1171px) {
        h1 {
            font-size: 2.7rem;
        }
    }

    @media (max-width: 700px) {
        height: 375px;
        padding: 10px;

        h1 {
            font-size: 1.7rem;
        }
    }
}

.Discover-SearchBanner {
    @include Discover-Banner;
    background: $primary-gradient;
}

.Discover-SearchAdBanner {
    @include Discover-Banner;
    background: $secondary-gradient;

    button, button:focus {
        font-size: 30px;
        margin-top: 50px;
        width: 190px;
    }
}

.Discover-Categories {
    margin: 35px 0px 0px 35px;

    // Category headers
    h1 {
        color: $primary;
        font-weight: bold;
    }

    // Category expand text
    h5 {
        color: $primary;
        text-decoration: underline;
    }

    .Discover-CategoryCards {
        overflow-y: scroll;
    }
}

.Discover-TypeCards {
    justify-content: center;
     h1 {
        color: $primary;
     }
}

.Discover-SearchContainer {
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
}

#search-icon {
    height: 30px;
    margin-right: 15px;
    margin-top: 15px;
}

.Discover-MobileTagline {
    display: none;
}

@media (max-width: 1171px) {
    .Discover-TypeCards {
        flex-wrap: wrap;
        margin-bottom: 20px;
    }
}

@media (max-width: 700px) {
    .Discover-Categories {
        margin: 20px 0px 0px 20px;
    }

    .Discover-DesktopTagline {
        display: none;
    }

    .Discover-MobileTagline {
        display: block;
    }
}