.ResourceContent-LearnMore {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
}

.ResourceContent-LearnMoreItem {
    display: flex;
    justify-content: center;
    align-items: center;

    border: solid 3px $primary;
    text-decoration: none;
    border-radius: 10px;
    padding: 10px;
    margin: 10px 20px 0px 0px;

    h3 {
        margin-right: 10px;
        margin-bottom: 0px;
    }

    h4 {
        margin-right: 10px;
        margin-bottom: 0px;
        color: $primary;
    }

    b, a {
        color: $primary;
    }

    h4::first-letter {
        text-transform: uppercase;
    }
}

.ResourceContent-LearnMoreItem:hover, .ResourceContent-LearnMoreItem:active {
    background-color: $primary;
    cursor: pointer;

    h3, a, b, h4 {
        color: $secondary;
    }
}

.ResourceContent-Parent {
    background-color: $primary;
    color: $secondary;
    border-bottom-left-radius: 20px;
    border-bottom-right-radius: 20px;

    height: 50px;
    padding: 14px 30px;
    margin-bottom: -5px;
    width: 100%;

    font-size: 16px;

    h5 {
        font-weight: bold;
    }

    b {
        color: $secondary;
    }
}

.ResourceContent-Services {
    max-height: 450px;
    margin-top: 20px;
}

.ResourceContent-Selected {
    background-color: $primary;
    color: $secondary;
}

.ResourceContent-Body {
    line-height: 1.5;
}

@media (max-width: 1000px) {
    .ResourceContent-Services {
        max-height: none;
    }
}

@media (max-width: 800px) {
    .ResourceContent-LearnMoreItem {
        padding: 5px 10px;

        img {
            width: 25px;
        }

        h4 {
            font-size: 19px;
        }
    }

    .Service-Text {
        font-size: 16px;
    }

    .ResourceContent {
        h2 {
            font-size: 26px;
        }
        h5 {
            font-size: 17px;
        }
    }

    .ResourceContent-Services {
        height: auto;
    }
}