.Footer {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 175px;
    background-color: $footer;

    p {
        color: $primary;
    }
}

.Footer-Text {
    display: flex;
    flex-direction: column;
    width: 300px;
    font-size: 12px;
    margin-right: 30px;
    font-weight: 300;
}

.Footer-Links {
    display: flex;
    flex-direction: row;
    font-weight: 300;
}

.Footer-Link, .Footer-Link:hover, .Footer-Link:active, .Footer-Link:focus {
    text-transform: uppercase;
    margin: 0px 10px;
    color: $primary;
}

#Footer-Logo {
    width: 150px;
    margin-bottom: 10px;
}

.COSTDIV {
    display: flex;
}

@media (max-width: 800px) {
    .Footer {
        flex-direction: column;
        height: 275px;
    }

    .Footer-Text {
        margin-bottom: 20px;
    }
}