.SearchBar {
    width: 500px;
    padding: 10px;
    border-radius: 20px;
    background-color: $secondary;

    margin: 20px 0px;

    img {
        width: 20px;
    }

    input, input:focus {
        border: none;
        outline: none;
        width: 90%;
        margin-left: 5px;
    }
}

.SearchBar-top {
    position: absolute;
    top: 0px;
    left: 35%;
}

.SearchBar-MobileContainer {
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: $primary;
    height: 70px;
}

.SearchBar-mobilepos {
    position: relative;
    margin-top: -20px;
}

.SearchBar-InsideBtn, .SearchBar-InsideBtn:hover {
    margin: 0px;
}


.SearchBar-OutsideBtn {
    display: none;
}

@media (max-width: 800px) {
    .SearchBar-OutsideBtn, .SearchBar-OutsideBtn:hover, .SearchBar-OutsideBtn:active {
        display: block;
        border: none;
        width: 100%;
        align-self: center;
        margin: 0px;

        margin-top: 20px;
    }

    .SearchBar-InsideBtn {
        display: none;
    }

    .SearchBar {
        width: 300px;
    }

}