// Fonts
$base: Lato;
$header: Poppins;
$hero: Montserrat;

// Colors
$primary: #7E41FF;
$primary-gradient: linear-gradient(89.88deg, rgba(158, 123, 247, 0.71) -91.34%, #7E41FF 8.89%, #4643E7 55.95%, #183D74 123.64%);
$secondary-gradient: linear-gradient(89.88deg, #E6AF2E -91.32%, #E6AF2E -19.67%, #7E41FF 75.51%, #7E41FF 123.64%);
$secondary: #FFFFFF;
$text: #000000;
$edit: #FF7A00;
$read: #17A600;
$delete: #D70202;
$footer: #F9F7F3;

$emergency: #DE1214;
$emergency-background: #FCE3DA;